import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCategoryJewerly.module.css';

const IconCategoryJewerly = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      x="0px" y="0px" 
      viewBox="-15 -5 90 60.5" 
      space="preserve"
    >
      <g><g><g><g><path d="M32,36.25c-17.94,0-32-6.23-32-14.18S14.06,7.89,32,7.89s32,6.23,32,14.18S49.94,36.25,32,36.25z M32,9.83      c-16.29,0-30.06,5.61-30.06,12.24S15.71,34.32,32,34.32s30.06-5.61,30.06-12.24S48.29,9.83,32,9.83z"/></g><g><path d="M32,56.11c-1.17,0-2.34-0.04-3.5-0.12C14.26,55.06,5.32,51.27,1.17,44.41c-0.55-0.89-0.98-2.43-0.98-3.5V23.53      c0-0.54,0.43-0.97,0.97-0.97s0.97,0.43,0.97,0.97v17.38c0,0.71,0.33,1.9,0.7,2.49c3.8,6.28,12.23,9.77,25.8,10.65      c2.23,0.15,4.51,0.15,6.76,0c13.55-0.89,21.99-4.37,25.8-10.65c0.36-0.59,0.68-1.76,0.68-2.49V23.6c0-0.54,0.43-0.97,0.97-0.97      s0.97,0.43,0.97,0.97v17.31c0,1.08-0.42,2.62-0.96,3.5c-4.16,6.86-13.1,10.64-27.32,11.57C34.34,56.07,33.17,56.11,32,56.11z"/></g></g><g><path d="M8.41,31.62c-0.38,0-0.75-0.23-0.9-0.61c-0.2-0.5,0.04-1.06,0.54-1.26c15.89-6.37,32-6.37,47.9,0     c0.5,0.2,0.74,0.76,0.54,1.26c-0.2,0.5-0.76,0.74-1.26,0.54c-15.42-6.18-31.05-6.18-46.46,0C8.65,31.6,8.53,31.62,8.41,31.62z"/></g></g><g><path d="M38.67,46.93c-1.09,0-2.18-0.23-3.22-0.7l-1.21-0.55c-1.44-0.65-3.1-0.69-4.57-0.11l-1.81,0.72    c-2.24,0.89-4.8,0.68-6.87-0.56l-2.47-1.48c-1.11-0.66-2.42-0.94-3.7-0.79l-1.29,0.16c-3.04,0.37-6.02-1.09-7.6-3.7l-0.59-0.98    c-0.51-0.85-1.24-1.56-2.1-2.05l-2.55-1.46c-0.46-0.27-0.63-0.86-0.36-1.32c0.27-0.46,0.86-0.63,1.32-0.36l2.55,1.46    c1.14,0.66,2.11,1.6,2.79,2.73l0.59,0.98c1.19,1.96,3.43,3.05,5.7,2.78l1.29-0.16c1.71-0.21,3.45,0.17,4.93,1.05l2.47,1.48    c1.55,0.93,3.48,1.08,5.16,0.42l1.81-0.72c1.95-0.77,4.17-0.72,6.08,0.14l1.21,0.55c1.85,0.84,3.97,0.66,5.66-0.47l1.56-1.04    c1.73-1.16,3.88-1.58,5.92-1.15c2.19,0.46,4.41-0.36,5.79-2.12l1.76-2.25c0.45-0.57,0.97-1.07,1.55-1.5l3.77-2.74    c0.43-0.31,1.04-0.22,1.36,0.21c0.31,0.43,0.22,1.04-0.21,1.36l-3.77,2.74c-0.44,0.32-0.83,0.7-1.16,1.12l-1.76,2.25    c-1.84,2.35-4.8,3.43-7.72,2.82c-1.53-0.32-3.15,0-4.44,0.87L43,45.61C41.69,46.49,40.18,46.93,38.67,46.93z"/></g></g>
    </svg>
  );
};

IconCategoryJewerly.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconCategoryJewerly.propTypes = { rootClassName: string, className: string };

export default IconCategoryJewerly;
