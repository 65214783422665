import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCategoryTrees.module.css';

const IconCategoryTrees = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      x="0px" y="0px" 
      viewBox="-20.0 0 140.0 95.0"
      space="preserve"
    >

<g xmlns="http://www.w3.org/2000/svg" fillRule="evenodd">
  <path d="m84.656 37.43c-1.4922-6.25-7.1172-10.902-13.82-10.902-2.7656 0-5.3477 0.79297-7.5352 2.1641-0.73047 0.45703-0.95312 1.4219-0.49219 2.1523 0.45703 0.73047 1.4219 0.95312 2.1523 0.49219 1.7031-1.0664 3.7148-1.6836 5.8711-1.6836 5.5859 0 10.211 4.1406 10.973 9.5156 0.10937 0.77734 0.77734 1.3516 1.5625 1.3438h0.10937c7.1055 0 12.871 5.7695 12.871 12.871 0 7.1055-5.7695 12.871-12.871 12.871-3.3672 0-6.4375-1.2969-8.7305-3.418-0.63281-0.58594-1.6211-0.54688-2.207 0.085937-0.58594 0.63281-0.54687 1.6211 0.085938 2.207 2.8516 2.6367 6.668 4.25 10.855 4.25 8.8281 0 15.996-7.168 15.996-15.996 0-8.4336-6.543-15.355-14.824-15.957zm-18.609 23.613c-1.6875 1.0391-3.6758 1.6406-5.8008 1.6406-3.3555 0-6.3672-1.4961-8.3984-3.8555-0.5625-0.65234-1.5508-0.72656-2.2031-0.16406-0.65234 0.5625-0.72656 1.5508-0.16406 2.2031 2.6055 3.0234 6.4648 4.9414 10.77 4.9414 2.7266 0 5.2773-0.76953 7.4414-2.1055 0.73438-0.45312 0.96094-1.418 0.51172-2.1484-0.45312-0.73438-1.4141-0.96484-2.1484-0.51172z"/>
  <path d="m28.129 55.906c-2.2773 1.1406-4.8438 1.7812-7.5625 1.7812-9.3398 0-16.922-7.582-16.922-16.922s7.582-16.922 16.922-16.922c0.86328 0 1.5625-0.69922 1.5625-1.5625s-0.69922-1.5625-1.5625-1.5625c-11.066 0-20.047 8.9805-20.047 20.047s8.9805 20.047 20.047 20.047c3.2227 0 6.2656-0.76172 8.9609-2.1133 0.76953-0.38672 1.082-1.3242 0.69531-2.0977-0.38672-0.76953-1.3242-1.082-2.0977-0.69531zm-4.3281-38.129c2.4492-4.6562 7.3359-7.8359 12.961-7.8359 7.4336 0 13.582 5.5547 14.508 12.734 0.089844 0.69141 0.625 1.2383 1.3086 1.3438 7.0039 1.0859 12.371 7.1523 12.371 14.457 0 8.0742-6.5547 14.633-14.633 14.633-3.3828 0-6.4961-1.1484-8.9766-3.0781-0.67969-0.53125-1.6641-0.40625-2.1914 0.27344-0.53125 0.67969-0.40625 1.6641 0.27344 2.1914 3.0078 2.3438 6.7891 3.7383 10.895 3.7383 9.8008 0 17.758-7.957 17.758-17.758 0-8.4727-5.9492-15.566-13.895-17.336-1.6016-8.1602-8.7969-14.328-17.426-14.328-6.8242 0-12.754 3.8555-15.727 9.5039-0.40234 0.76172-0.10547 1.707 0.65625 2.1094 0.76172 0.40234 1.7109 0.10547 2.1094-0.65625z"/>
  <path d="m25.496 80.301c-0.16406 0.46094-0.10156 0.97266 0.16797 1.3789 0.26953 0.41016 0.71094 0.66797 1.1992 0.69922 0.14453 0.007813 0.28906 0.019532 0.43359 0.03125 4.9961 0.40234 9.875 1.4727 14.902 1.6758 0.52734 0.019531 1.0273-0.22266 1.3359-0.65234 0.30469-0.42969 0.375-0.98438 0.18359-1.4727-4.5547-11.75-4.8789-23.609-0.019531-35.598 0.68359-1.668 0.039062-3.5898-1.5195-4.5117l-2.1641-1.2812c-1.6758-0.99219-3.8359-0.49219-4.9023 1.1367l-0.8125 1.2383-0.8125-1.2383c-1.0703-1.6289-3.2266-2.1289-4.9023-1.1367-0.69531 0.41406-1.4961 0.88281-2.1719 1.293-1.543 0.91406-2.1875 2.832-1.5039 4.5039 4.6211 11.418 4.5469 22.727 0.58594 33.93zm3.582-0.85938c3.5742-11.312 3.3906-22.723-1.2734-34.25 0-0.003906 0-0.003906-0.003907-0.007812-0.097656-0.23438-0.003906-0.50781 0.21484-0.63672 0.003906-0.003906 0.007813-0.003906 0.011719-0.007813 0.66797-0.40234 1.457-0.87109 2.1484-1.2773 0.23828-0.14062 0.54688-0.070313 0.69922 0.16406 0.92969 1.418 2.1211 3.2266 2.1211 3.2266 0.28906 0.44141 0.77734 0.70703 1.3047 0.70703 0.52734 0 1.0156-0.26562 1.3047-0.70703 0 0 1.1875-1.8086 2.1172-3.2266 0.15234-0.23047 0.46094-0.30469 0.69922-0.16406l2.1641 1.2773c0.22266 0.13281 0.31641 0.40625 0.21484 0.64453 0 0.003906-0.003906 0.003906-0.003906 0.007812-4.8555 11.988-4.8633 23.855-0.83203 35.617-3.6523-0.32422-7.25-0.98438-10.895-1.3711z"/>
  <path d="m68.484 76.375 0.78906-21.27c0.03125-0.86328-0.64063-1.5859-1.5039-1.6211-0.86328-0.03125-1.5859 0.64062-1.6172 1.5039l-0.84766 22.812c-0.015626 0.42188 0.14062 0.83203 0.42969 1.1367s0.69531 0.48047 1.1172 0.48438c0.25 0.003906 0.50391 0.007813 0.76172 0.023437 2.1523 0.10547 4.1641 0.64063 6.0703 1.4023 0.49219 0.19531 1.0469 0.12891 1.4805-0.17578 0.43359-0.30469 0.67969-0.80859 0.66016-1.3359l-0.90234-24.348c-0.03125-0.86328-0.75781-1.5352-1.6211-1.5039s-1.5352 0.75781-1.5039 1.6211l0.82031 22.062c-1.3281-0.39453-2.7031-0.67188-4.1289-0.79688z"/>
  <path d="m90.562 90.059h-84.684c-0.125 0-0.23828-0.070313-0.28516-0.18359-0.046875-0.10547-0.035156-0.22656 0.035156-0.31641 0.007813-0.007813 0.011719-0.015625 0.019532-0.023438 1.1719-1.332 2.6445-2.4922 4.1992-3.4961 4.8477-3.1328 11.48-4.1094 17.453-3.625 5.3203 0.42969 10.508 1.6094 15.879 1.707 3.0547 0.054687 5.9102-0.51562 8.7031-1.3008 0.83203-0.23047 1.3164-1.0977 1.082-1.9258-0.23047-0.83203-1.0977-1.3164-1.9258-1.082-2.5039 0.70312-5.0625 1.2344-7.8047 1.1836-5.3047-0.097656-10.426-1.2695-15.684-1.6953-6.6484-0.53906-14.008 0.62891-19.402 4.1172-1.793 1.1602-3.4805 2.5039-4.8359 4.043l-0.015625 0.015624c-0.89453 1.0156-1.1055 2.457-0.55078 3.6914 0.55859 1.2305 1.7852 2.0234 3.1367 2.0195h84.734c1.4375 0 2.6875-0.98438 3.0273-2.3828 0.33594-1.3984-0.32812-2.8438-1.6094-3.5h-0.003906c-2.8789-1.4727-5.7188-3.0039-8.457-4.6602-4.7266-2.8555-9.7852-6.0273-15.809-6.3164-4.1133-0.19922-7.8438 0.75391-11.508 1.8867-0.82422 0.25391-1.2891 1.1289-1.0312 1.9531 0.25391 0.82422 1.1289 1.2852 1.9531 1.0312 3.3242-1.0273 6.7031-1.9297 10.438-1.75 5.4844 0.26562 10.043 3.2695 14.348 5.8711 2.7891 1.6836 5.6797 3.2422 8.6055 4.7422zm-87.195-2.6602c0.16016-0.16016 0.35156-0.28516 0.56641-0.36328-0.23438 0.085938-0.41406 0.20703-0.56641 0.36328z"/>
 </g>

    </svg>
  );
};

IconCategoryTrees.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconCategoryTrees.propTypes = { rootClassName: string, className: string };

export default IconCategoryTrees;


