import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFacebook.module.css';

const IconFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      viewBox="0 0 32 32"
      space="preserve"
    >
      <path d="M30 0a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"></path>
      <path fill="#fff" d="M22.94 16H18.5v-3c0-1.27.62-2.5 2.6-2.5h2.02V6.56s-1.83-.31-3.58-.31c-3.65 0-6.04 2.21-6.04 6.22V16H9.44v4.62h4.06V32h5V20.62h3.73z"></path>
    </svg>
  );
};

IconFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconFacebook.propTypes = { rootClassName: string, className: string };

export default IconFacebook;
