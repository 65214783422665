import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCategoryUrn.module.css';

const IconCategoryUrn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      x="0px" y="0px" 
      viewBox="-90 100 1220.000000 800.000000"
      space="preserve"
    >

<g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path d="M5000 9049 c-398 -35 -749 -146 -899 -285 -98 -90 -106 -115 -152
-426 -25 -169 -38 -290 -35 -317 12 -91 85 -185 183 -235 60 -30 86 -58 114
-121 50 -113 33 -238 -47 -344 -25 -34 -49 -73 -53 -87 -6 -23 -17 -29 -81
-46 -191 -49 -402 -135 -508 -207 -69 -46 -142 -124 -167 -178 -38 -79 -119
-506 -150 -783 -91 -826 12 -1636 301 -2367 35 -89 86 -209 112 -265 116 -246
304 -548 471 -757 l92 -113 -95 -127 c-108 -145 -127 -193 -106 -269 35 -131
239 -233 620 -311 522 -106 1233 -81 1662 60 118 38 198 82 265 143 69 63 96
114 97 186 1 55 0 57 -97 187 -53 73 -97 135 -97 138 0 4 43 61 96 128 600
755 930 1827 901 2917 -11 402 -68 831 -155 1160 -24 94 -93 183 -195 252
-120 82 -351 175 -514 209 -57 12 -64 16 -73 45 -6 17 -26 50 -46 72 -131 153
-102 397 56 477 101 51 174 144 186 236 9 69 -69 576 -97 630 -49 96 -110 151
-249 220 -149 74 -312 119 -580 160 -157 23 -585 34 -760 18z m601 -269 c334
-34 624 -120 726 -216 l38 -36 -25 -20 c-62 -51 -210 -101 -390 -132 -202 -35
-306 -41 -665 -40 -395 1 -543 14 -771 69 -134 31 -257 89 -262 122 -9 62 238
169 503 219 225 41 613 57 846 34z m-1286 -592 c263 -96 747 -144 1216 -119
412 21 646 64 855 157 10 5 34 -111 34 -166 0 -37 -151 -98 -335 -134 -560
-112 -1546 -67 -1843 83 -34 17 -62 37 -62 44 0 6 5 49 12 95 9 70 14 82 27
76 9 -4 52 -20 96 -36z m345 -552 c178 -25 374 -36 644 -36 281 0 468 11 671
40 71 10 131 17 132 16 2 -1 -2 -29 -8 -61 -17 -90 6 -217 58 -318 30 -60 32
-58 -112 -117 -185 -77 -420 -113 -745 -112 -333 0 -621 46 -784 126 -106 51
-101 46 -73 102 44 88 61 154 61 244 1 47 -3 98 -7 114 -8 28 -7 28 25 22 18
-3 80 -12 138 -20z m-291 -686 c100 -58 284 -112 511 -147 165 -26 674 -26
840 0 233 36 407 87 522 152 l48 27 97 -22 c318 -72 573 -184 619 -271 13 -26
13 -32 -1 -53 -24 -37 -72 -70 -171 -119 -243 -121 -639 -205 -1116 -238 -235
-15 -830 -7 -1028 15 -414 47 -736 126 -943 233 -85 44 -167 109 -167 133 0 6
19 31 41 55 77 83 301 177 572 240 56 13 107 24 114 24 7 1 35 -12 62 -29z
m-769 -647 c121 -73 464 -178 725 -223 772 -131 1749 -91 2361 96 114 34 268
98 336 139 68 40 60 53 87 -140 40 -281 46 -384 46 -735 -1 -367 -10 -492 -60
-787 -132 -777 -434 -1447 -889 -1970 -44 -51 -85 -93 -91 -93 -5 0 -71 -8
-145 -16 -295 -33 -973 -29 -1368 8 l-123 11 -63 68 c-175 185 -367 471 -517
769 -385 768 -534 1707 -419 2635 10 83 24 181 30 218 12 66 13 67 34 54 11
-7 37 -23 56 -34z m945 -3983 c273 -26 440 -33 775 -34 203 0 432 5 515 12
177 15 341 31 372 38 18 3 32 -9 73 -62 27 -37 50 -71 50 -76 0 -15 -137 -73
-226 -97 -262 -69 -661 -103 -986 -83 -317 19 -656 82 -793 147 -63 30 -63 26
10 118 42 53 48 57 78 52 18 -2 77 -9 132 -15z"/>
<path d="M2713 8780 c-71 -36 -93 -117 -50 -181 50 -75 142 -86 204 -24 42 42
51 92 27 141 -38 75 -109 100 -181 64z"/>
<path d="M7395 8128 c-145 -40 -114 -259 37 -258 72 0 128 60 128 137 0 80
-86 143 -165 121z"/>
<path d="M2019 7821 c-33 -33 -39 -45 -39 -82 0 -87 64 -146 148 -136 66 7
104 49 110 121 4 54 3 57 -36 96 -36 36 -44 40 -92 40 -47 0 -56 -4 -91 -39z"/>
<path d="M8040 7442 c-121 -64 -71 -242 67 -242 47 0 100 31 114 68 49 124
-66 235 -181 174z"/>
<path d="M1913 5710 c-57 -34 -68 -62 -68 -175 0 -119 15 -154 77 -186 47 -24
90 -21 134 7 41 27 54 71 54 181 0 80 -4 104 -20 130 -37 61 -116 81 -177 43z"/>
<path d="M1531 5322 c-47 -24 -72 -61 -73 -108 0 -53 24 -99 65 -121 39 -22
205 -25 252 -5 98 41 98 197 0 238 -51 21 -200 19 -244 -4z"/>
<path d="M2191 5323 c-91 -46 -95 -177 -8 -228 34 -20 142 -31 211 -21 119 18
162 149 75 232 -29 28 -34 29 -137 32 -85 2 -114 -1 -141 -15z"/>
<path d="M1918 5049 c-58 -30 -73 -67 -73 -179 0 -109 12 -142 64 -177 62 -42
136 -26 178 36 20 30 23 46 23 139 0 93 -3 110 -22 139 -25 36 -73 63 -110 63
-12 -1 -40 -10 -60 -21z"/>
<path d="M2294 4367 c-68 -62 -54 -171 27 -213 84 -43 189 18 189 111 0 88
-47 135 -135 135 -37 0 -52 -6 -81 -33z"/>
<path d="M8423 4119 c-52 -33 -66 -77 -61 -189 4 -104 16 -135 64 -167 36 -23
108 -21 142 4 51 37 64 77 60 188 -3 77 -8 107 -22 128 -39 57 -123 74 -183
36z"/>
<path d="M1395 3860 c-51 -20 -90 -93 -79 -144 15 -70 62 -106 140 -106 39 0
51 5 79 33 62 62 55 158 -14 204 -35 24 -88 29 -126 13z"/>
<path d="M8040 3722 c-64 -34 -88 -118 -50 -179 35 -58 62 -68 180 -68 84 0
111 4 133 18 87 57 85 181 -4 229 -48 26 -210 26 -259 0z"/>
<path d="M8703 3720 c-42 -25 -63 -59 -65 -103 -3 -91 52 -138 171 -145 100
-6 151 9 191 55 29 33 32 41 28 89 -8 95 -56 124 -200 124 -75 0 -99 -4 -125
-20z"/>
<path d="M8441 3463 c-65 -34 -76 -61 -76 -182 0 -85 4 -112 18 -134 41 -63
120 -83 177 -45 53 36 70 78 70 178 0 61 -5 95 -18 119 -35 69 -109 97 -171
64z"/>
<path d="M7744 2507 c-91 -83 -31 -227 95 -227 37 0 49 6 82 39 35 34 39 44
39 87 -1 86 -48 134 -133 134 -40 0 -53 -5 -83 -33z"/>
</g>
    </svg>
  );
};

IconCategoryUrn.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconCategoryUrn.propTypes = { rootClassName: string, className: string };

export default IconCategoryUrn;
