import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCategoryBoat.module.css';

const IconCategoryBoat = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      x="0px" y="0px" 
      viewBox="15 15 70 45.5" 
      space="preserve"
    >
      <path 
        d="M84.08,44.85c-0.21-0.33-0.58-0.52-0.97-0.49c-0.11,0.01-5.43,0.45-12.97,1.1c-2.55-4.64-9.82-7.01-21.61-7.06  c-0.1,0-0.2,0-0.3,0c-8.61,0-16.6,1.28-16.69,1.29c-0.4,0.06-0.72,0.35-0.83,0.74c-0.11,0.39,0.01,0.8,0.31,1.07l3.34,2.93  l-4.43,5.91c-2.05,0.71-3.83,1.54-5.32,2.52c-0.11,0.07-0.21,0.16-0.28,0.27l-3.54,5.03c-0.94-0.49-2.09-0.95-3.99-0.95  c-0.57,0-1.04,0.47-1.04,1.04s0.47,1.04,1.04,1.04c1.76,0,2.6,0.47,3.56,1.01c1.07,0.6,2.27,1.28,4.58,1.28  c2.31,0,3.52-0.68,4.58-1.28c0.97-0.54,1.8-1.01,3.56-1.01s2.6,0.47,3.56,1.01c1.07,0.6,2.27,1.28,4.58,1.28  c2.31,0,3.52-0.68,4.58-1.28c0.97-0.54,1.8-1.01,3.56-1.01c1.76,0,2.6,0.47,3.56,1.01c1.07,0.6,2.27,1.28,4.58,1.28  c2.31,0,3.52-0.68,4.58-1.28c0.97-0.54,1.8-1.01,3.56-1.01c1.76,0,2.6,0.47,3.56,1.01c1.07,0.6,2.27,1.28,4.58,1.28  c0.57,0,1.04-0.47,1.04-1.04s-0.47-1.04-1.04-1.04c-1.6,0-2.43-0.38-3.29-0.86c1.44-0.62,2.89-1.46,4.33-2.53  c1.64-1.22,3.28-2.72,4.86-4.47c2.69-2.97,4.28-5.61,4.35-5.72C84.28,45.6,84.28,45.18,84.08,44.85z M36.6,44.92  c0.33-0.43,0.26-1.05-0.15-1.41l-2.36-2.07c2.93-0.38,8.39-0.95,14.13-0.95c0.1,0,0.21,0,0.31,0c4.92,0.02,9.02,0.46,12.22,1.29  l-12.03,0.71c-0.57,0.03-1.01,0.53-0.98,1.1c0.03,0.55,0.49,0.98,1.04,0.98c0.02,0,0.04,0,0.06,0l15.79-0.93  c0.18-0.01,0.35-0.07,0.5-0.16c1.12,0.63,2,1.37,2.64,2.19c-7.84,0.68-17.53,1.54-26.22,2.37c-3.07,0.29-5.84,0.74-8.31,1.35  L36.6,44.92z M78.17,50.29c-2.32,2.56-5.96,5.87-10.13,7.06c-0.09,0.03-0.17,0.06-0.25,0.11c-0.59-0.14-1.28-0.23-2.11-0.23  c-2.31,0-3.52,0.68-4.58,1.28c-0.97,0.54-1.8,1.01-3.56,1.01c-1.76,0-2.6-0.47-3.56-1.01c-1.07-0.6-2.27-1.28-4.58-1.28  s-3.52,0.68-4.58,1.28c-0.97,0.54-1.8,1.01-3.56,1.01c-1.76,0-2.6-0.47-3.56-1.01c-1.07-0.6-2.27-1.28-4.58-1.28  s-3.52,0.68-4.58,1.28c-0.97,0.54-1.8,1.01-3.56,1.01c-1,0-1.7-0.15-2.29-0.38l3.25-4.63c3.55-2.25,8.88-3.73,15.85-4.4  c15.1-1.44,33.22-2.98,39.36-3.49C80.41,47.61,79.41,48.92,78.17,50.29z"
      />
    </svg>
  );
};

IconCategoryBoat.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconCategoryBoat.propTypes = { rootClassName: string, className: string };

export default IconCategoryBoat;
